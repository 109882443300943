import {
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { KEY_ENTER, KEY_ESCAPE } from '@/constants/keys';
import { Root, InputS, InputLabel, ErrorText } from './index.styled';

export { ErrorText };

/* eslint-disable react/jsx-props-no-spreading */

export interface InputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'ref' | 'as'> {
  value?: string;
  error?: ReactNode;
  onChange?: (value: string) => void;
  children?: ReactNode;
}

export function PureInput({
  error,
  onChange,
  autoFocus,
  ...props
}: Omit<InputProps, 'children'>) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocus) ref.current?.focus();
  }, [ref, autoFocus]);

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return <InputS ref={ref} onChange={onInput} hasError={!!error} {...props} />;
}

export function Input({
  className,
  error,
  label,
  children,
  ...props
}: InputProps) {
  return (
    <Root className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PureInput {...props} error={error} />
      {children}

      {error && <ErrorText>{error}</ErrorText>}
    </Root>
  );
}

export interface SubmitInputProps extends InputProps {
  changeOnBlur?: boolean;
  onCancel?: () => void;
}

export function SubmitInput({
  value = '',
  onChange,
  onCancel,
  onKeyUp,
  onBlur,
  changeOnBlur,
  ...props
}: SubmitInputProps) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value, setCurrentValue]);

  const onInput = useCallback(
    (val: string) => {
      setCurrentValue(val);
    },
    [setCurrentValue]
  );

  const onInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (changeOnBlur && onChange) onChange(currentValue || '');
      if (onBlur) onBlur(e);
      if (onCancel) onCancel();
    },
    [onChange, onBlur, onCancel, changeOnBlur, currentValue]
  );

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === KEY_ENTER && onChange) {
        onChange(currentValue || '');
      } else if (e.key === KEY_ESCAPE) {
        setCurrentValue(changeOnBlur ? currentValue : value);
        if (onCancel) onCancel();
      }
      if (onKeyUp) onKeyUp(e);
    },
    [
      onChange,
      onCancel,
      currentValue,
      setCurrentValue,
      value,
      changeOnBlur,
      onKeyUp,
    ]
  );

  return (
    <Input
      value={currentValue}
      onChange={onInput}
      onKeyUp={onEnter}
      onBlur={onInputBlur}
      {...props}
    />
  );
}
