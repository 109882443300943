import { DefaultTheme } from 'styled-components';
import Color from 'color';
import {
  baseFont,
  lightTheme,
  disabledStyle,
  headerFont,
  monospaceFont,
} from '@/constants/themes';

export interface ThemeProps {
  theme: DefaultTheme;
}

export type ThemeColor = keyof (typeof lightTheme)['colors'];

export const color =
  (name: ThemeColor, opacity?: number) => (props: ThemeProps) =>
    opacity
      ? Color(props.theme.colors[name]).alpha(opacity).toString()
      : props.theme.colors[name];

type ColorConditionFn<T> = (
  props: ThemeProps & T
) => boolean | null | undefined;

type ColorConditionObject<T> = {
  [clr in keyof (typeof lightTheme)['colors']]?: ColorConditionFn<T>;
};

export const conditionalColor =
  <T>(defaultColor: ThemeColor, conditions: ColorConditionObject<T>) =>
  (props: ThemeProps & T) => {
    const pair = Object.entries(conditions).find(
      ([, condition]) => condition && condition(props)
    );
    const name = pair ? pair[0] : defaultColor;
    return props.theme.colors[name];
  };

export const boxShadow = (index: number) => (props: ThemeProps) =>
  props.theme.boxShadows[index];

export const borderRadius = () => (props: ThemeProps) =>
  [props.theme.borderRadius, 'px'].join('');

export interface DisabledProps {
  disabled?: boolean;
}

export const disabledProp = () => (props: ThemeProps & DisabledProps) =>
  props.disabled && disabledStyle;

export { baseFont, headerFont, monospaceFont };
