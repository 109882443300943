import styled, { css } from 'styled-components';
import {
  baseFont,
  borderRadius,
  color,
  conditionalColor,
  disabledProp,
} from '@/lib/styled';
import { Link } from './link';

export interface ButtonSProps {
  primary?: boolean;
  primary2?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  $hasIcon?: boolean;
}

const buttonPrimaryStyle = css`
  border: 0;
  color: ${color('buttonPrimaryText')};
  background-color: ${conditionalColor('buttonPrimaryBackground', {
    buttonPrimary2Background: ({ primary2 }: ButtonSProps) => primary2,
  })};

  &:hover,
  &:active {
    background-color: ${conditionalColor('buttonPrimaryActiveBackground', {
      buttonPrimary2ActiveBackground: ({ primary2 }: ButtonSProps) => primary2,
    })};
  }
`;

export const buttonSecondaryStyle = css`
  font-weight: 400;
  color: ${color('buttonSecondaryText')};
  background-color: ${conditionalColor('buttonSecondaryBackground', {
    transparent: (props: ButtonSProps) => !!props.ghost,
  })};

  ${(props: ButtonSProps) =>
    props.ghost &&
    css`
      padding: 12px;
    `}

  &:hover,
  &:active {
    color: ${color('buttonSecondaryActiveText')};
    background-color: ${conditionalColor('buttonSecondaryActiveBackground', {
      transparent: (props: ButtonSProps) => !!props.ghost,
    })};
  }
`;

const disabledStyle = css`
  cursor: default;
  pointer-events: none;
`;

export const buttonStyle = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 0;
  outline: 0;
  border-radius: ${borderRadius()};
  padding: 12px 16px;
  ${({ $hasIcon }: ButtonSProps) => $hasIcon && `padding-left: 12px;`};
  line-height: 18px;
  ${baseFont};
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;

  ${disabledProp};

  &:active {
    transform: scale(0.96);
  }

  ${(props: ButtonSProps) =>
    props.primary || props.primary2
      ? buttonPrimaryStyle
      : buttonSecondaryStyle};
  ${(props: ButtonSProps) => props.disabled && disabledStyle};

  svg {
    display: block;
  }
`;

export const ButtonS = styled.button`
  ${buttonStyle}
`;

export const ButtonLinkS = styled(Link)`
  ${buttonStyle}
`;

export const ButtonIcon = styled.div`
  flex: 0 1 24px;

  svg {
    max-height: 100%;
  }
`;

export const ButtonContent = styled.div`
  &:empty {
    display: none;
  }
`;
