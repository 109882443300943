import jwtDecode, { JwtPayload } from 'jwt-decode';
import { omit } from '@taskode/lib/objects';

type TokenData<T> = {
  payload: T;
  expires?: Date;
};

export function decodeToken<T>(token: string): TokenData<T> {
  if (!token) {
    throw new Error('No token passed');
  }
  const data = jwtDecode<T & JwtPayload>(token);
  return {
    payload: omit(data, ['iss', 'sub', 'aud', 'exp', 'nbf', 'iat', 'jti']) as T,
    expires: data.exp ? new Date(data.exp * 1000) : undefined,
  };
}
