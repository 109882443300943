import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { borderRadius, color, disabledProp } from '@/lib/styled';
import { Select } from '@/components/ui/select';
import { LAYOUT_PADDING } from '@/constants/layout';
import {
  SelectButtonS,
  SelectOptionS,
} from '@/components/ui/select/index.styled';

export const HeaderWrap = styled.div`
  position: relative;
  margin: 16px;
`;

export const HeaderS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 8px;
  gap: 24px;
  border-radius: ${borderRadius()} ${borderRadius()} 0 0;
  background-color: ${color('backgroundPrimary')};

  &:only-child {
    border-radius: ${borderRadius()};
  }

  @media (min-width: 768px) {
    padding: 0 24px;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (min-width: 1200px) {
    gap: 120px;
  }
`;

export const HeaderLogoLink = styled.a`
  margin-right: 24px;
  font-size: 24px;
  text-decoration: none;
  color: ${color('textPrimary')};
`;

export const HeaderLinkHide = styled.span`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
`;

interface SubHeaderSProps {
  mode?: 'compact' | 'mobile';
}

export const HeaderLinkIcon = styled.div`
  svg {
    display: block;
    height: 24px;
  }
`;

export const HeaderLinkText = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`;

export const HeaderLinkS = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  padding: 0 64px;
  text-decoration: none;
  color: inherit;
  opacity: 0.75;

  &.active {
    font-weight: 600;
    color: ${color('subHeaderActiveText')};
    opacity: 1;
    background-color: ${color('subHeaderActiveBackground')};
  }

  ${disabledProp()}
`;

export const SubHeaderS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 48px;
  padding: 0 ${LAYOUT_PADDING};
  border-radius: 0 0 ${borderRadius()} ${borderRadius()};
  background-color: ${color('subHeaderBackground')};
  color: ${color('subHeaderText')};

  ${(props: SubHeaderSProps) => {
    if (props.mode === 'compact') {
      return css`
        ${HeaderLinkS} {
          padding: 0 32px;
        }

        ${HeaderLinkHide} {
          display: none;
        }
      `;
    }
    if (props.mode === 'mobile') {
      return css`
        ${HeaderLinkS} {
          padding: 0 16px;

          &.active {
            padding: 0 32px;
          }
        }

        ${HeaderLinkText} {
          display: none;
        }
      `;
    }
    return undefined;
  }}
`;

export const SubHeaderCopy = styled(SubHeaderS)`
  position: absolute;
  left: -9999px;
`;

export const PureHeaderContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;

export const TeamSelectWrap = styled.div`
  position: relative;
  flex: 1 1 auto;
  height: 42px;
`;

export const TeamSelectS = styled(Select)`
  position: absolute;
  max-width: 100%;
  border: 0;

  ${SelectButtonS} {
    font-weight: 600;
    padding-left: 0;
  }

  ${SelectOptionS} {
    font-weight: 600;
    padding-left: 16px;
  }
`;
