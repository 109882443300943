import styled from 'styled-components';
import { boxShadow, color } from '@/lib/styled';
import { InlineAlert } from '@/components/inline-alert';

export const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  max-width: 800px;
  padding: 0 16px;
`;

export const NotificationItemS = styled(InlineAlert)`
  position: relative;
  width: 100%;
  margin: 16px 0;
  box-shadow: ${boxShadow(0)};
  background-color: ${color('backgroundPrimary')};
`;
