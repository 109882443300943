import { PropsWithChildren } from 'react';
import { Logo } from '@/components/logo';
import { TeamSelect } from './team-select';
import { UserMenu } from './user-menu';
import { SubHeader } from './sub-header';
import { HeaderLink } from './header-link';
import { PureHeader } from './pure-header';
import {
  HeaderLinkHide,
  HeaderLogoLink,
  HeaderRight,
  HeaderS,
  HeaderWrap,
} from './index.styled';

export { HeaderLink, HeaderLinkHide, PureHeader };

export type HeaderProps = {
  showTeams?: boolean;
};

export function Header({
  showTeams,
  children,
}: PropsWithChildren<HeaderProps>) {
  return (
    <HeaderWrap>
      <HeaderS>
        {showTeams ? <TeamSelect /> : <div />}

        <HeaderRight>
          <UserMenu />

          <HeaderLogoLink href="/">
            <Logo />
          </HeaderLogoLink>
        </HeaderRight>
      </HeaderS>

      {children && <SubHeader>{children}</SubHeader>}
    </HeaderWrap>
  );
}
