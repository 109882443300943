import styled, { css } from 'styled-components';
import { borderRadius, color } from '@/lib/styled';

export const Root = styled.label`
  position: relative;
  text-align: left;
`;

interface InputSProps {
  hasError?: boolean;
}

export const InputS = styled.input`
  width: 100%;
  padding: 12px;
  border: solid 1px ${color('inputBackground')};
  border-radius: ${borderRadius()};
  color: ${color('inputText')};
  background-color: ${color('inputBackground')};
  transition: border-color 0.2s, opacity 0.2s;

  &:focus {
    border-color: ${color('inputActiveBorder')};
  }

  ${(props: InputSProps) =>
    props.hasError &&
    css`
      border-color: ${color('inputError')};
    `}

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const InputLabel = styled.div`
  position: absolute;
  left: 0;
  top: -10px;
  z-index: 1;
  max-width: calc(100% - 24px);
  padding: 2px 9px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  background-color: ${color('inputBackground')};
`;

export const ErrorText = styled.div`
  margin: 8px 13px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: #c71919;
  text-transform: uppercase;
`;
