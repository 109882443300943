import Cookies from 'universal-cookie';
import { decodeToken } from '@/lib/jwt';
import { User } from '@/graphql/generated-types';

export type RefreshTokenResponse = {
  user: User;
  expires: number;
};

let currentRefreshRequest: Promise<Response> | null = null;

export async function fetchRefreshToken(): Promise<RefreshTokenResponse> {
  if (!currentRefreshRequest) {
    currentRefreshRequest = fetch('/auth/refresh-token', {
      method: 'POST',
    });
  }
  const res = await currentRefreshRequest;
  const payload: RefreshTokenResponse = await res.json();
  currentRefreshRequest = null;

  setTimeout(() => {}, payload.expires - Date.now() - 100);

  return payload;
}

export async function fetchLogout() {
  return fetch('/auth/logout', { method: 'POST' });
}

export function checkAccessToken() {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  if (accessToken) {
    decodeToken(accessToken);
  } else {
    throw new Error('Access token is empty');
  }
}
