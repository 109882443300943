import { ReactNode } from 'react';
import { HeaderLinkS, HeaderLinkIcon, HeaderLinkText } from './index.styled';

export interface HeaderLinkProps {
  to: string;
  icon: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
}

export function HeaderLink({ to, icon, disabled, children }: HeaderLinkProps) {
  return (
    <HeaderLinkS to={to} disabled={disabled}>
      <HeaderLinkIcon>{icon}</HeaderLinkIcon>
      {children && <HeaderLinkText>{children}</HeaderLinkText>}
    </HeaderLinkS>
  );
}
