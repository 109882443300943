import styled, { css } from 'styled-components';
import { Select, SelectOption } from '@/components/ui/select';
import { ReactComponent as AutoIconS } from '@/assets/icons/chrome.svg';
import { ReactComponent as LightIconS } from '@/assets/icons/sun.svg';
import { ReactComponent as DarkIconS } from '@/assets/icons/moon.svg';
import { SelectButton } from '@/components/ui/select/button';

export const ThemeSelectS = styled(Select)`
  border: 0;
`;

const optionStyle = css`
  gap: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const ThemeOption = styled(SelectOption)`
  ${optionStyle};
`;

export const ThemeButton = styled(SelectButton)`
  background-color: transparent;

  & > div {
    padding: 0;
    ${optionStyle};
  }
`;

const iconStyle = css`
  width: 16px;
  height: 16px;
`;

export const AutoIcon = styled(AutoIconS)`
  ${iconStyle};
`;

export const LightIcon = styled(LightIconS)`
  ${iconStyle};
`;

export const DarkIcon = styled(DarkIconS)`
  ${iconStyle};
`;
