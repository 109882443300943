import { useMemo } from 'react';
import { Portal } from 'react-portal';
import { Notification, useNotifications } from '@/contexts/notifications';
import { InlineAlertButton } from '@/components/inline-alert';
import { NotificationsContainer, NotificationItemS } from './index.styled';

type NotificationItemProps = {
  id: string;
  notification: Notification;
};

function NotificationItem({
  id,
  notification: { status, title, content },
}: NotificationItemProps) {
  const { removeNotification } = useNotifications();
  const buttons = useMemo<InlineAlertButton[]>(
    () => [
      { text: 'Close', onClick: () => removeNotification(id), primary: true },
    ],
    [removeNotification, id]
  );
  return (
    <NotificationItemS status={status} title={title} buttons={buttons}>
      {content}
    </NotificationItemS>
  );
}

export function Notifications() {
  const { notifications } = useNotifications();
  const entries = Object.entries(notifications).reverse();

  if (entries.length === 0) return null;

  return (
    <Portal>
      <NotificationsContainer>
        {entries.map(([id, notification]) => (
          <NotificationItem key={id} id={id} notification={notification} />
        ))}
      </NotificationsContainer>
    </Portal>
  );
}
