import { ReactNode, useEffect, useRef } from 'react';
import { noop } from '@/lib/noop';
import { SelectOptionS } from './index.styled';

export interface SelectOptionItem<T = string> {
  value: T;
  text?: string;
  icon?: ReactNode;
  group?: boolean;
  disabled?: boolean;
}

export interface SelectOptionProps extends SelectOptionItem {
  onClick: (value: string) => void;
  onHover: (value: string) => void;
  inGroup?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  className?: string;
}

export function SelectOption({
  value,
  text,
  icon,
  group,
  inGroup,
  onClick,
  onHover,
  isSelected,
  isActive,
  disabled,
  className,
}: SelectOptionProps) {
  const ref = useRef<HTMLElement>(null);
  const onItemClick = () => onClick(value);
  const onMouseEnter = () => onHover(value);

  useEffect(() => {
    if (!isActive || !ref.current) return;
    ref.current.scrollIntoView({ block: 'nearest' });
  }, [isActive]);

  if (group) {
    return (
      <SelectOptionS icon={icon} onClick={noop} disabled>
        {text}
      </SelectOptionS>
    );
  }

  return (
    <SelectOptionS
      ref={ref}
      icon={icon}
      inGroup={inGroup}
      onClick={onItemClick}
      onMouseEnter={onMouseEnter}
      isSelected={isSelected}
      isActive={isActive}
      disabled={disabled}
      className={className}
    >
      {text}
    </SelectOptionS>
  );
}
