import { ThemeMode, useTheme } from '@/contexts/theme';
import { SelectOptionItem } from '@/components/ui/select';
import {
  ThemeSelectS,
  ThemeOption,
  ThemeButton,
  AutoIcon,
  LightIcon,
  DarkIcon,
} from './index.styled';

const options: SelectOptionItem<ThemeMode>[] = [
  {
    value: 'auto',
    text: 'Auto',
    icon: <AutoIcon />,
  },
  {
    value: 'light',
    text: 'Light',
    icon: <LightIcon />,
  },
  {
    value: 'dark',
    text: 'Dark',
    icon: <DarkIcon />,
  },
];

export function ThemeSelect() {
  const { theme, setTheme } = useTheme();

  return (
    <ThemeSelectS
      options={options}
      onChange={(value) => setTheme(value as ThemeMode)}
      value={theme}
      OptionComponent={ThemeOption}
      ButtonComponent={ThemeButton}
    />
  );
}
