import { createGlobalStyle } from 'styled-components';

import { color, baseFont } from '@/lib/styled';
import { fonts } from './fonts';

export const GlobalStyle = createGlobalStyle`
  ${fonts}
  
  :root {
    --textPrimary: ${color('textPrimary')};
    --textSecondary: ${color('textSecondary')};
    --backgroundPrimary: ${color('backgroundPrimary')};
    --backgroundSecondary: ${color('backgroundSecondary')};
    --backgroundTertiary: ${color('backgroundTertiary')};
    --action: ${color('action')};
    --actionDarker: ${color('actionDarker')};
    --accent: ${color('accent')};
    --accentDarker: ${color('accentDarker')};
    --accent2: ${color('accent2')};
    --accent2Darker: ${color('accent2Darker')};
  }

  * {
    box-sizing: border-box;
  }

  html {
    all: initial;
    background: ${color('backgroundSecondary')};
    color: ${color('textPrimary')};
    ${baseFont};
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
  }

  *:focus {
    outline: none;
  }

  h1 {
    font-size: 24px;
    font-weight: normal;
  }

  h1,
  h2,
  h3 {
    ${baseFont};
    
    strong {
      font-weight: inherit;
      color: ${color('accent')};
    }
  }
  
  h4 {
    font-size: 14px;
    font-weight: normal;
  }

  input {
    ${baseFont};
    color: ${color('inputText')};
  }
  
  button {
    ${baseFont};
  }
  
  a {
    color: ${color('linkText')};
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${color('scrollbar')};
  }
  
  * {
    scrollbar-color: ${color('scrollbar')} transparent;
    scrollbar-width: thin;
  }
`;
