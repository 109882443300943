import { css } from 'styled-components';
import { lightTheme } from './light';
import { darkTheme } from './dark';

export { lightTheme, darkTheme };

export const baseFontFamily = 'Montserrat, sans-serif';

export const baseFont = css`
  font-family: ${baseFontFamily};
`;

export const headerFont = css`
  font-family: ${baseFontFamily};
  font-weight: 500;
`;

export const monospaceFontFamily = '"Anonymous Pro", monospace';

export const monospaceFont = css`
  font-family: ${monospaceFontFamily};
`;
export const disabledStyle = css`
  cursor: default;
  opacity: 0.5 !important;
  pointer-events: none;
  tab-index: -1;
`;
