import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from '@/lib/noop';
import { omit } from '@taskode/lib/objects';
import { uniqid } from '@/lib/id';

const DEFAULT_TIMEOUT = 10000;

export type NotificationStatus = 'info' | 'success' | 'error';

export type Notification = {
  id?: string;
  status: NotificationStatus;
  title?: string;
  content?: ReactNode;
  timeout?: number;
};

export type NotificationsContextValue = {
  notifications: Record<string, Notification>;
  addNotification: (notification: Notification) => string;
  removeNotification: (id: string) => void;
};

export const NotificationsContext = createContext<NotificationsContextValue>({
  notifications: {},
  addNotification: () => 'notificationId',
  removeNotification: noop,
});

export function NotificationsProvider({ children }: PropsWithChildren) {
  const timeouts = useRef<Record<string, number>>({});

  useEffect(
    () => () => Object.values(timeouts.current).forEach(window.clearTimeout),
    []
  );

  const [notifications, setNotifications] = useState<
    Record<string, Notification>
  >({});

  const removeNotification = useCallback((id: string) => {
    setNotifications((prev) => omit(prev, [id]));
    window.clearTimeout(timeouts.current[id]);
  }, []);

  const addNotification = useCallback(
    (notification: Notification) => {
      const id = notification.id || uniqid('notification');

      setNotifications((prev) => ({
        ...prev,
        [id]: notification,
      }));

      timeouts.current[id] = window.setTimeout(
        removeNotification,
        notification.timeout ?? DEFAULT_TIMEOUT,
        id
      );

      return id;
    },
    [removeNotification]
  );

  const value = useMemo<NotificationsContextValue>(
    () => ({
      notifications,
      addNotification,
      removeNotification,
    }),
    [notifications, addNotification, removeNotification]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationsContext);
