import { PropsWithChildren } from 'react';

import { Logo } from '@/components/logo';
import {
  HeaderLogoLink,
  HeaderRight,
  HeaderS,
  HeaderWrap,
  PureHeaderContent,
} from './index.styled';

export type PureHeaderProps = {
  hideLogo?: boolean;
};

export function PureHeader({
  hideLogo,
  children,
}: PropsWithChildren<PureHeaderProps>) {
  return (
    <HeaderWrap>
      <HeaderS>
        <PureHeaderContent>{children}</PureHeaderContent>

        {!hideLogo && (
          <HeaderRight>
            <HeaderLogoLink href="/">
              <Logo />
            </HeaderLogoLink>
          </HeaderRight>
        )}
      </HeaderS>
    </HeaderWrap>
  );
}
