import { forwardRef, HTMLProps, ReactNode, Ref } from 'react';

import {
  ButtonS,
  ButtonLinkS,
  ButtonIcon,
  ButtonContent,
  buttonStyle,
} from './index.styled';

export { buttonStyle };

/* eslint-disable react/jsx-props-no-spreading */

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'ref' | 'as' | 'type'> {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
  href?: string;
  title?: string;
  disabled?: boolean;
  primary?: boolean;
  primary2?: boolean;
  ghost?: boolean;
}

export const Button = forwardRef(
  (
    {
      type = 'button',
      className,
      icon,
      children,
      onClick,
      href,
      title,
      disabled,
      primary,
      primary2,
      ghost,
      ...props
    }: ButtonProps,
    ref: Ref<any>
  ) => {
    if (href !== undefined) {
      return (
        <ButtonLinkS
          // @ts-ignore
          ref={ref}
          className={className}
          onClick={onClick}
          to={href}
          title={title}
          primary={primary}
          primary2={primary2}
          ghost={ghost}
          disabled={disabled}
          $hasIcon={!!icon}
        >
          {icon && <ButtonIcon>{icon}</ButtonIcon>}
          {children}
        </ButtonLinkS>
      );
    }

    return (
      <ButtonS
        ref={ref}
        type={type}
        className={className}
        onClick={onClick}
        title={title}
        disabled={disabled}
        primary={primary}
        primary2={primary2}
        ghost={ghost}
        $hasIcon={!!icon}
        {...props}
      >
        {icon && <ButtonIcon>{icon}</ButtonIcon>}
        <ButtonContent>{children}</ButtonContent>
      </ButtonS>
    );
  }
);

export const ButtonLink = ButtonLinkS;
