import styled from 'styled-components';
import { borderRadius, boxShadow, color } from '@/lib/styled';

export const PopupMenuS = styled.div`
  position: fixed;
  left: -9999px;
  top: -9999px;
  z-index: 2;
  max-width: calc(100% - 32px);
  margin: 4px 0;
  padding: 8px 0;
  border-radius: ${borderRadius()};
  background-color: ${color('backgroundPrimary')};
  box-shadow: ${boxShadow(0)};
`;
