import { KeyboardEvent, ReactNode } from 'react';
import { KEY_ENTER } from '@/constants/keys';
import { SelectOptionItem } from './option';
import {
  SearchInputS,
  SelectArrow,
  SelectButtonS,
  SelectButtonItem,
  SelectButtonPlaceholder,
} from './index.styled';

export type SelectButtonProps = {
  search?: boolean;
  searchText?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onEnter?: () => void;
  selectedOption?: SelectOptionItem;
  disabled?: boolean;
  error?: ReactNode;
  className?: string;
};

export function SelectButton({
  search,
  searchText,
  placeholder,
  onChange,
  onEnter,
  selectedOption,
  disabled,
  error,
  className,
}: SelectButtonProps) {
  const onKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === KEY_ENTER) onEnter?.();
  };

  return search ? (
    <>
      <SearchInputS
        placeholder={placeholder}
        value={searchText}
        onChange={onChange}
        onKeyDown={onKey}
      />
      <SelectArrow />
    </>
  ) : (
    <SelectButtonS className={className} disabled={disabled} hasError={!!error}>
      <SelectButtonItem icon={selectedOption?.icon}>
        {selectedOption?.text ?? (
          <SelectButtonPlaceholder>{placeholder}</SelectButtonPlaceholder>
        )}
      </SelectButtonItem>
      <SelectArrow />
    </SelectButtonS>
  );
}
