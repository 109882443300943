import styled, { css } from 'styled-components';
import { borderRadius, color, disabledProp, DisabledProps } from '@/lib/styled';
import { PureInput } from '@/components/ui/input';
import { PopupMenu } from '@/components/ui/popup-menu';
import { MenuItem } from '@/components/ui/popup-menu/menu-item';
import { ReactComponent as ArrowIcon } from '@/assets/icons/chevron-down.svg';

export const SearchInputS = styled(PureInput)`
  height: 42px;
  padding-right: 40px;
  border: 0;
`;

interface SelectOptionSProps {
  isActive?: boolean;
  group?: boolean;
  inGroup?: boolean;
}

export const SelectOptionS = styled(MenuItem)`
  font-size: 14px;
  ${({ inGroup }: SelectOptionSProps) =>
    inGroup &&
    css`
      padding-left: 32px;
    `};
`;

interface SelectButtonProps extends DisabledProps {
  hasError?: boolean;
}

export const SelectButtonS = styled.button.attrs(() => ({ type: 'button' }))`
  position: relative;
  display: block;
  width: 100%;
  height: 42px;
  padding: 0 40px 0 0;
  border: solid 1px ${color('inputBackground')};
  border-radius: ${borderRadius()};
  outline: 0;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.2s;
  color: ${color('selectText')};
  background-color: ${color('inputBackground')};

  ${disabledProp};

  ${(props: SelectButtonProps) =>
    props.hasError &&
    css`
      border-color: ${color('inputError')};
    `}

  & ${SelectOptionS}:hover, ${SelectOptionS}:active {
    background-color: transparent;
  }
`;

export const SelectButtonItem = styled(SelectOptionS)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const SelectButtonPlaceholder = styled.span`
  color: ${color('textSecondary')};
`;

export const SelectArrow = styled(ArrowIcon)`
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  color: ${color('selectText')};
  pointer-events: none;
`;

export const SelectPopupWrap = styled.div`
  width: 100%;
`;

export const SelectPopupMenu = styled(PopupMenu)`
  margin-top: 0;
  max-height: 40vh;
  overflow-y: auto;

  &::before {
    content: none;
  }
`;

interface SelectWrapProps {
  isOpen?: boolean;
}

export const SelectRoot = styled.div`
  position: relative;
  display: inline-block;
  border-radius: ${borderRadius};
  border: solid 1px ${color('inputBackground')};

  &:focus-within {
    border-color: ${color('inputActiveBorder')};
  }

  ${(props: SelectWrapProps) =>
    props.isOpen &&
    css`
      ${SelectArrow} {
        transform: rotate(180deg);
      }
    `}
`;
