import * as logger from '@/lib/logger';

type DataSourceItem<T> = {
  data: T;
  version: number;
  expires?: number;
};

function isDataSourceItem(data: any) {
  return typeof data.version === 'number';
}

function getDataSource<T>(isSessionStorage?: boolean) {
  const source = isSessionStorage ? sessionStorage : localStorage;

  function setData(key: string, data: DataSourceItem<T>) {
    try {
      source.setItem(key, JSON.stringify(data));
    } catch (e) {
      logger.error(e);
    }
  }

  function getData(key: string): DataSourceItem<T> | undefined {
    const data = source.getItem(key);
    if (!data) return undefined;
    const parsed = JSON.parse(data);
    if (isDataSourceItem(parsed)) return parsed;
    const dsItem: DataSourceItem<T> = { data: parsed, version: 1 };
    setData(key, dsItem);
    return dsItem;
  }

  function removeData(key: string) {
    source.removeItem(key);
  }

  return { getData, setData, removeData };
}

export function storage<T>(
  key: string,
  defaultData: T,
  isSessionStorage?: boolean
) {
  const dataSource = getDataSource<T>(isSessionStorage);
  let timeout: number | undefined;

  return {
    set(data: T, ttl?: number): void {
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = undefined;
      }

      const dsItem: DataSourceItem<T> = { data, version: 1 };

      if (ttl) {
        dsItem.expires = Date.now() + ttl;
        timeout = window.setTimeout(() => dataSource.removeData(key), ttl);
      }

      dataSource.setData(key, dsItem);
    },

    get(): T {
      try {
        const dsItem = dataSource.getData(key);
        if (dsItem === undefined) return defaultData;

        if (dsItem.expires && Date.now() < dsItem.expires) {
          dataSource.removeData(key);
          return defaultData;
        }

        return dsItem.data;
      } catch (e) {
        return defaultData;
      }
    },

    remove(): void {
      dataSource.removeData(key);
    },
  };
}
