import Color from 'color';
import { DefaultTheme } from 'styled-components';

const textPrimary = '#020415';
const textSecondary = '#7b889a';

const backgroundPrimary = '#ffffff';
const backgroundSecondary = '#f0f4f8';
const backgroundTertiary = Color(backgroundSecondary).darken(0.02).toString();

const action = '#ff6f00';
const actionDarker = Color(action).darken(0.15).hex().toString();

const accent = '#195fc7';
const accentDarker = Color(accent).darken(0.25).hex().toString();

const accent2 = '#ff615f';
const accent2Darker = Color(accent2).darken(0.25).hex().toString();

const skeletonColor1 = Color(backgroundSecondary).darken(0.02).toString();
const skeletonColor2 = Color(backgroundSecondary).darken(0.05).toString();

const error = '#c71919';
const success = '#288818';

export const colors = {
  transparent: 'transparent',

  textPrimary,
  textSecondary,

  action,

  accent,
  accent2,

  linkText: accent,

  backgroundPrimary,
  backgroundSecondary,
  backgroundTertiary,

  subHeaderBackground: '#cfdce8',
  subHeaderText: textPrimary,
  subHeaderActiveText: textPrimary,
  subHeaderActiveBackground: backgroundSecondary,

  buttonActionText: textPrimary,
  buttonActionBackground: action,
  buttonActionActiveBackground: actionDarker,

  buttonPrimaryText: '#f6f6f7',

  buttonPrimaryBackground: accent,
  buttonPrimaryActiveBackground: accentDarker,

  buttonPrimary2Background: accent2,
  buttonPrimary2ActiveBackground: accent2Darker,

  buttonSecondaryText: textPrimary,
  buttonSecondaryActiveText: textPrimary,
  buttonSecondaryBackground: backgroundSecondary,
  buttonSecondaryActiveBackground: Color(backgroundSecondary)
    .darken(0.05)
    .toString(),

  buttonGhostText: accent,

  errorText: '#8f0000',
  successText: success,

  checkboxBorder: '#394c68',
  checkboxCheck: '#394c68',

  inputText: textPrimary,
  inputBackground: '#f6f6f7',
  inputActiveBorder: accent,
  inputError: error,

  selectText: '#5a6a81',

  border: '#dce0e4',

  userAvatar1: '#8f0000',
  userAvatar2: '#c34300',
  userAvatar3: '#007038',
  userAvatar4: '#0043a6',
  userAvatar5: '#7d00be',
  userAvatar6: '#a3400d',
  userAvatar7: '#226875',
  userAvatar8: '#7c6c33',

  rateStar: '#f2994a',

  socketOffline: '#7b889a',
  socketConnecting: '#f2994a',
  socketConnected: success,
  socketError: '#8f0000',

  skeletonColor: skeletonColor1,
  skeletonGradient: `linear-gradient(
    90deg, ${skeletonColor1} 320px, 
    ${skeletonColor2} 640px, 
    ${skeletonColor1} 960px
  )`,

  videoBackground: '#626260',

  scrollbar: 'rgba(128, 128, 128, 0.25)',
};

export const boxShadows = [
  '0 1px 4px rgba(37, 40, 42, 0.2)',
  '0 1px 2px rgba(37, 40, 42, 0.25)',
];

export const borderRadius = 8;

export const lightTheme: DefaultTheme = { colors, boxShadows, borderRadius };
