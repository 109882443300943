import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { KEY_DOWN, KEY_ENTER, KEY_UP } from '@/constants/keys';
import { ErrorText } from '@/components/ui/input';
import { SelectButton } from './button';
import { SelectOption, SelectOptionItem } from './option';
import { SelectPopupMenu, SelectRoot, SelectPopupWrap } from './index.styled';

export { SelectOption };
export type { SelectOptionItem };

export interface SelectProps {
  value?: string;
  options: SelectOptionItem[];
  onChange: (value: string) => void;
  search?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: ReactNode;
  OptionComponent?: typeof SelectOption;
  ButtonComponent?: typeof SelectButton;
  className?: string;
}

export function Select({
  value,
  options,
  onChange,
  search,
  placeholder,
  disabled,
  error,
  OptionComponent = SelectOption,
  ButtonComponent = SelectButton,
  className,
}: SelectProps) {
  const ref = useRef(null);
  const popupRef = useRef(null);

  const selectedOption = useMemo(
    () => options.find((o) => o.value === value),
    [options, value]
  );

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [activeValue, setActiveValue] = useState<string | null>(null);
  useEffect(() => {
    if (!isOpen || options.length === 0) return () => {};

    const onKey = (e: KeyboardEvent) => {
      const last = options.length - 1;
      const index = options.findIndex((o) => o.value === activeValue);

      switch (e.code) {
        case KEY_ENTER:
          e.preventDefault();
          if (activeValue !== null) onChange(activeValue);
          break;

        case KEY_DOWN: {
          e.preventDefault();
          const idx = activeValue === null || index >= last ? 0 : index + 1;
          setActiveValue(options[idx]?.value ?? null);
          break;
        }

        case KEY_UP: {
          e.preventDefault();
          const idx = activeValue === null || index <= 0 ? last : index - 1;
          setActiveValue(options[idx]?.value ?? null);
          break;
        }

        default:
          break;
      }
    };

    window.addEventListener('keydown', onKey);

    return () => window.removeEventListener('keydown', onKey);
  }, [isOpen, options, activeValue, onChange]);

  useEffect(() => {
    const popup = popupRef.current;
    if (!popup) return () => {};

    const onScroll = (e: Event) => e.stopPropagation();

    // @ts-ignore
    popup.addEventListener('scroll', onScroll);

    // @ts-ignore
    return () => popup.removeEventListener('scroll', onScroll);
  }, [popupRef]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const option = options.find((o) => {
      const text = searchText.toLowerCase();
      return (
        o.value.toLowerCase().includes(text) ||
        o.text?.toLowerCase().includes(text)
      );
    });

    if (option) setActiveValue(option.value);
  }, [searchText, options]);

  useEffect(() => {
    if (!selectedOption) return;
    setSearchText(selectedOption.text ?? selectedOption.value);
  }, [selectedOption, setSearchText]);

  const hasGroups = useMemo(() => options.some((o) => o.group), [options]);

  const onInputEnter = () => {
    if (activeValue !== null) onChange(activeValue);
  };

  return (
    <SelectRoot className={className} ref={ref} isOpen={isOpen}>
      <ButtonComponent
        search={search}
        searchText={searchText}
        onChange={setSearchText}
        onEnter={onInputEnter}
        placeholder={placeholder}
        disabled={disabled}
        selectedOption={selectedOption}
        error={error}
      />

      {error && <ErrorText>{error}</ErrorText>}

      {!disabled && (
        <SelectPopupWrap ref={popupRef}>
          <SelectPopupMenu
            anchor={ref}
            onOpen={onOpen}
            onClose={onClose}
            stretch
            key={String(value)}
          >
            {options.map((o) => (
              <OptionComponent
                key={String(o.value || o.text)}
                value={o.value}
                icon={o.icon}
                group={o.group}
                inGroup={!o.group && hasGroups}
                onClick={onChange}
                onHover={setActiveValue}
                text={o.text}
                isSelected={value === o.value}
                isActive={activeValue === o.value}
              />
            ))}
          </SelectPopupMenu>
        </SelectPopupWrap>
      )}
    </SelectRoot>
  );
}
