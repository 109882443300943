import {
  ComponentType,
  createContext,
  Suspense,
  lazy,
  ReactNode,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';

type LoaderCallback = (isStarting: boolean) => void;

export const LoaderContext = createContext<[boolean, LoaderCallback]>([
  false,
  () => {},
]);

export type LoaderProviderProps = {
  children: ReactNode;
};

export function LoaderProvider({ children }: LoaderProviderProps) {
  const [counter, setCounter] = useState<number>(0);

  const setLoading = useCallback((isStarting: boolean) => {
    setCounter((prevCounter) => {
      const decr = prevCounter > 0 ? prevCounter - 1 : 0;
      return isStarting ? prevCounter + 1 : decr;
    });
  }, []);

  return (
    <LoaderContext.Provider value={[counter > 0, setLoading]}>
      {children}
    </LoaderContext.Provider>
  );
}

export const useLoader = () => useContext(LoaderContext);

export function load<T = {}>(
  promise: () => Promise<{ default: ComponentType<T> }>,
  Fallback?: ComponentType<any>
) {
  type Props = T & { children?: ReactNode };
  let Lazy: ReturnType<typeof lazy>;

  return ({ children, ...props }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [, setLoading] = useContext(LoaderContext);

    useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

    if (!Lazy) {
      setIsLoading(true);

      const promiseWrap = () => promise().finally(() => setIsLoading(false));

      Lazy = lazy<ComponentType<T>>(promiseWrap);
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <Suspense fallback={Fallback ? <Fallback {...props} /> : undefined}>
        <Lazy {...props}>{children}</Lazy>
      </Suspense>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  };
}
