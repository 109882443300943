import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Operation,
  NextLink,
  from,
  Observable,
} from '@apollo/client';
import * as logger from '@/lib/logger';
import { APOLLO_SERVER_URI } from '@/constants/apollo';
import { checkAccessToken, fetchRefreshToken } from '@/lib/auth';

const refreshMiddleware = new ApolloLink(
  (operation: Operation, forward: NextLink) =>
    new Observable((observer) => {
      (async () => {
        try {
          checkAccessToken();
        } catch (accessTokenError) {
          try {
            await fetchRefreshToken();
          } catch (refreshTokenError) {
            logger.warn(refreshTokenError);
          }
        }

        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })();
    })
);

const httpLink = new HttpLink({ uri: APOLLO_SERVER_URI });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([refreshMiddleware, httpLink]),
});
