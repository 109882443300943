import process from 'process';
import Rollbar from 'rollbar';

export const rollbar = process.env.ROLLBAR_POST_CLIENT_ITEM_TOKEN
  ? new Rollbar({
      accessToken: process.env.ROLLBAR_POST_CLIENT_ITEM_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: process.env.NODE_ENV,
      codeVersion: `web-${process.env.VERSION}`,
      logLevel: 'error',
    })
  : undefined;
