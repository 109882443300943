import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import * as logger from '@/lib/logger';
import { noop } from '@/lib/noop';
import { darkTheme, lightTheme } from '@/constants/themes';
import { storage } from '@/lib/storage';

const themes = {
  light: lightTheme,
  dark: darkTheme,
} as const;

export type ThemeName = keyof typeof themes;
export type ThemeMode = ThemeName | 'auto';

function detectTheme(theme: ThemeMode): ThemeName {
  if (theme !== 'auto') return theme;
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}

const themeStorage = storage<ThemeMode>('theme', 'auto');

export type ThemeContextValue = {
  theme: ThemeMode;
  activeTheme: ThemeName;
  setTheme: (mode: ThemeMode) => void;
};

export const ThemeContext = createContext<ThemeContextValue>({
  theme: 'light',
  activeTheme: 'light',
  setTheme: noop,
});

export type ThemeProviderProps = PropsWithChildren<{}>;

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = useState<ThemeMode>(() => themeStorage.get());
  const [activeTheme, setActiveTheme] = useState(() => detectTheme(theme));

  useEffect(() => {
    themeStorage.set(theme);
    setActiveTheme(detectTheme(theme));

    try {
      const listener = () => setActiveTheme(detectTheme(theme));
      const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
      matchMedia.addEventListener('change', listener);
      return () => matchMedia.removeEventListener('change', listener);
    } catch (e) {
      logger.info('Theme detection not supported');
      return noop;
    }
  }, [theme]);

  const value = useMemo<ThemeContextValue>(
    () => ({ theme, activeTheme, setTheme }),
    [theme, activeTheme, setTheme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={themes[activeTheme]}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);
